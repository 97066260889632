<template>
  <div class="a-password">
    <label :for="id">{{ $t(label) }}</label>
    <div class="a-password__input">
      <input
        :type="type"
        v-bind:value="value"
        :id="id"
        class="form-control"
        :class="{ 'is-invalid': invalid }"
        @input="onInput"
        :disabled="disabled"
      />
      <button class="a-password__showhide" type="button" :class="hideClass" @click="showPassword($event)"></button>
    </div>
    <div v-show="invalid" class="invalid-feedback" :class="{ 'is-invalid': invalid }">{{ $t(invalidText) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    invalidText: {},
    label: {},
    value: {},
    submitted: {},
    disabled: {},
    id: {
      default() {
        return `input-${this._uid}`
      }
    }
  },
  computed: {
    invalid() {
      return this.invalidText && this.submitted && !this.value
    }
  },
  data() {
    return {
      type: 'password',
      hideClass: ''
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },
    showPassword(event) {
      if (this.type === 'password') {
        this.type = 'text'
        this.hideClass = 'a-password__showhide--hide'
      } else {
        this.type = 'password'
        this.hideClass = ''
      }
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';

.a-password {
  &__input {
    position: relative;
  }
  &__showhide {
    display: block;
    position: absolute;
    right: 2px;
    bottom: 0;
    height: $input-height;
    width: 32px;
    outline: 0;
    border: 0;
    background-color: transparent;
    background-image: url(../../assets/eye_show.svg);
    background-position: center center;
    background-size: 16px auto;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }

    &--hide {
      background-image: url(../../assets/eye_hide.svg);
    }
  }
}
</style>
