<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 order-sm-2">
        <div class="row row-login-form align-items-sm-center justify-content-center">
          <div class="col-xs-10 col-md-8"><reset-password-form></reset-password-form></div>
        </div>
      </div>
      <div class="col-sm-6 order-sm-1 d-none d-sm-block">
        <div class="row row-login-info align-items-sm-center justify-content-center">
          <div class="col-xs-10 col-md-8"><login-message /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResetPasswordForm from '@/components/organism/ResetPasswordForm'
import LoginMessage from '@/components/molecules/LoginMessage'
require('../utilities/grid')
export default {
  components: { ResetPasswordForm, LoginMessage }
}
</script>

<style lang="scss" scoped>
@import '../utilities/config';
.row-login-info {
  min-height: 100vh;
  height: auto;
  padding-top: 100px;
  padding-bottom: 50px;
}

.row-login-form {
  min-height: 100vh;
  height: auto;
  background-color: gray('gray-100');
  padding-top: 100px;
  padding-bottom: 50px;
}
</style>
